import React from 'react';
import Theme from '../../components/Theme/Theme';
import Layout from '../../components/Layout/Layout';
import SEO from '../../components/SEO/Seo';
import Container from '../../components/Container/Container';
import NewsletterUnsubscribe from '../../components/NewsletterUnsubscribe/NewsletterUnsubscribe';

const NewsletterConfirmationPage = props => (
    <Layout>
        <SEO title="Désinscription à la newsletter" />
        <Theme>
            <Container size="MD">
                <NewsletterUnsubscribe search={props.location.search} />
            </Container>
        </Theme>
    </Layout>
);

export default NewsletterConfirmationPage;
