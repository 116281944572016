import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Button from '../Button/Button';
import SvgComponent from '../../assets/svg/SvgComponent';
import {
    unsubscribeToNewsletter,
    unsubscriptionReason,
    resubscribeToNewsletter,
} from '../../dataManager/newsletterDataManager';
import styles from './NewsletterUnsubscribe.module.scss';

const NewsletterUnsubscribe = ({ search }) => {
    const [token] = useState(getTokenFromUrlParams(search));
    const [componentState, setComponentState] = useState('default');
    const [resubscribeError, setResubscribeError] = useState();

    useEffect(() => {
        async function unsusbscribeWithToken() {
            const response = await unsubscribeToNewsletter(token);

            if (response.status !== 200) {
                setComponentState('invalidToken');
            }
        }

        if (token) {
            unsusbscribeWithToken();
        }
    }, [token]);

    const handleFormSubmitted = () => {
        setComponentState('thanks');
    };

    function getTokenFromUrlParams(params) {
        var urlParams = new URLSearchParams(params);
        return urlParams.get('token');
    }

    const handleWelcomeBack = async () => {
        const response = await resubscribeToNewsletter(token);

        if (response.status === 200) {
            setComponentState('welcomeBack');
        } else {
            setResubscribeError(
                'Oups, erreur technique, réessaie plus tard...'
            );
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.box}>
                {componentState === 'invalidToken' && <InvalidToken />}
                {componentState === 'default' && (
                    <UnsubscribeForm
                        onFormSubmitted={handleFormSubmitted}
                        token={token}
                    />
                )}
                {componentState === 'thanks' && <ThanksState />}
                {componentState === 'welcomeBack' && <Resubscribe />}
            </div>
            {componentState !== 'welcomeBack' &&
                componentState !== 'invalidToken' && (
                    <React.Fragment>
                        <p className={styles.textRight}>
                            Tu t'es désinscrit par erreur,
                            <button
                                onClick={handleWelcomeBack}
                                className={styles.btnlink}
                            >
                                réabonnes-toi
                            </button>
                        </p>
                        {resubscribeError && (
                            <p
                                className={cx(
                                    styles.textRight,
                                    styles.errorMessage
                                )}
                            >
                                {resubscribeError}
                            </p>
                        )}
                    </React.Fragment>
                )}
        </div>
    );
};

const UnsubscribeForm = ({ onFormSubmitted, token }) => {
    const [reason, setReason] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const handleChange = e => {
        setReason(e.target.value);
    };
    const handleSubmit = async e => {
        e.preventDefault();
        setErrorMessage(null);

        if (!reason) {
            setErrorMessage(`Sélectionne d'abord un motif`);
            return;
        }

        setIsLoading(true);
        const response = await unsubscriptionReason(token, reason);
        setIsLoading(false);
        if (response.status === 200) {
            onFormSubmitted();
        } else if (response.status === 500) {
            const data = await response.json();
            setErrorMessage(data.error);
        }
    };
    return (
        <React.Fragment>
            <SvgComponent
                name="newsletterUnsubscribed"
                className={styles.svg}
            />
            <h1>Tu n'es plus abonné à notre newsletter</h1>
            <p>
                Si tu as quelques secondes, peux-tu nous dire pourquoi tu t'es
                désinscrit:
            </p>
            <form className={styles.form}>
                {[
                    {
                        label: 'Je ne veux plus recevoir ces emails',
                        value: 'nolonger',
                    },
                    {
                        label: 'Ces emails sont trops fréquents',
                        value: 'toooften',
                    },
                    {
                        label: `Le contenu ne m'intéresse pas`,
                        value: 'uninteresting',
                    },
                    { label: 'Autre', value: 'other' },
                ].map(field => (
                    <label key={field.value} className={styles.label}>
                        <input
                            type="radio"
                            name="reason"
                            className={styles.input}
                            value={field.value}
                            onChange={handleChange}
                        />
                        {field.label}
                    </label>
                ))}
                <Button.Component
                    onClick={handleSubmit}
                    className={styles.button}
                >
                    {isLoading ? (
                        <div className={styles.btnLoadingContainer}>
                            <SvgComponent
                                name="loader"
                                className={styles.btnLoadingSvg}
                            />
                            <span className={styles.btnLoadingText}>
                                Envoi en cours
                            </span>
                        </div>
                    ) : (
                        <>Envoyer</>
                    )}
                </Button.Component>
                {errorMessage && (
                    <p className={styles.errorMessage}>{errorMessage}</p>
                )}
            </form>
        </React.Fragment>
    );
};

const InvalidToken = () => {
    return (
        <div>
            <SvgComponent
                name="newsletterUnsubscribed"
                className={styles.thanksSvg}
            />
            <p>Hmmm, ce lien ne semble pas valide...</p>
        </div>
    );
};

const ThanksState = () => {
    return (
        <div>
            <SvgComponent name="thanksHead" className={styles.thanksSvg} />
            <p>Merci pour ton retour, ça nous est précieux</p>
        </div>
    );
};

const Resubscribe = () => {
    return (
        <div>
            <SvgComponent name="welcomeBackHead" className={styles.thanksSvg} />
            <p>Content de te revoir parmi nous !</p>
        </div>
    );
};

export default NewsletterUnsubscribe;
